import React, { useState, useRef } from "react";

import Grid from "@material-ui/core/Grid";
import { StaticImage } from "gatsby-plugin-image";

export default function NiinaFooter(props) {
  return (
    <div
      style={{
        background: "#603813",
        display: "row",
        padding: "2em",
        fontSize: "3.125vw",
        color: "white",
      }}
    >
      <p>Bakery Niina　(ベーカリーニーナ)</p>
      <p>営業時間　7:00～18:00 (売り切れ次第終了)</p>
      <p>定休日　火曜日</p>
      <p>住所 990-0823 山形県山形市下条町2-3-22</p>
      <p>TEL. 023-676-8899</p>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Grid
          alignItem="center"
          direction="row"
          justify="space-between"
          alignItems="center"
          container
          style={{
            margin: 0,
            padding: 0,
            width: "50%",
            minWidth: "160px",
            position: "relative",
          }}
        >
          <a href="https://www.instagram.com/bakery_niina/">
            <StaticImage
              placeholder="none"
              // width="8vw"
              // height="8vw"
              style={imageStyle}
              imgStyle={{ width: "100%", height: "auto", margin: "auto" }}
              src="../images/SVG/99.sns-insta.svg"
              layout="constrained"
            />
          </a>
          <StaticImage
            style={imageStyle}
            placeholder="none"
            imgStyle={{ width: "100%", height: "auto", margin: "auto" }}
            src="../images/SVG/99.sns-line.svg"
            // width={"8vw"}
            layout="constrained"
          />
          <a href="https://www.facebook.com/bakeryniina">
            <StaticImage
              placeholder="none"
              style={imageStyle}
              imgStyle={{ width: "100%", height: "auto", margin: "auto" }}
              src="../images/SVG/99.sns-fb.svg"
              // width={"8vw"}
              layout="constrained"
            />
          </a>
          <StaticImage
            placeholder="none"
            style={imageStyle}
            imgStyle={{ width: "100%", height: "auto", margin: "auto" }}
            src="../images/SVG/99.sns-mail.svg"
            // width={"8vw"}
            layout="constrained"
          />
        </Grid>
      </div>
    </div>
  );
}

const imageStyle = {
  width: "8vw",
  maxWidth: "8vw",
  height: "8vw",
  maxHeight: "8vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
