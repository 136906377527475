import React, { useState, useRef, useEffect } from "react";

import { Link } from "gatsby";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import { StaticImage } from "gatsby-plugin-image";

export default function NiinaHeader(props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [headerVisible, setHeaderVisible] = useState("hidden");

  useEffect(()=>{
    setTimeout(()=>{
      setHeaderVisible("visible");
    }, 500)
  })

  


  const menuStyle = openMenu
    ? { Width: "160px", width: "40vw", background: "#EC6C00", opacity: 0.8 }
    : { background: "#EC6C00", opacity: 0.8, };

  console.log(menuStyle, openMenu);

  return (
    <Grid
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      container
      // style={{ position: "fixed", zIndex: "100", width: "100vw" }}
      style={{
        visibility: headerVisible,
        position: "fixed",
        zIndex: "100",
        margin: 0,
        padding: 0,
        minWidth: "320px",
      }}
      className="niina-header"
    >
      {/* <Button ref={menu} style={{ margin: 0, padding: 0 }}> */}
      <div style={menuStyle}>
        <div onClick={(e) => {
            console.log(window);
            setOpenMenu(e.currentTarget);
          }}
>
        <StaticImage
          placeholder="none"
          src="../images/SVG/80.burgar.svg"
          style={{ width: "4em" }}
        />
        </div>
        {openMenu ? (
          <div
            onClick={() => {
              setOpenMenu(false);
            }}
            style={{
              top: "-4em",
              position: "absolute",
              width: "40vw",
              height: "100vh",
            }}
          />
        ) : null}
        {openMenu ? (
          <List component="nav" aria-label="mailbox folders">
            <ListItem
              button
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <label htmlFor="index" style={{ width: "100%", height: "100%" }}>
                <a id="index" href="/" style={{ textDecoration: "none" }}>
                  <ListItemText
                    style={{ color: "white", fontSize: "5vw" }}
                    primary="トップページ"
                  />
                </a>
              </label>
            </ListItem>
            <Divider />
            <ListItem
              button
              divider
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <label
                htmlFor="professionalism"
                style={{ width: "100%", height: "100%" }}
              >
                <Link
                  id="professionalism"
                  to="/professionalism"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemText
                    style={{ color: "white", fontSize: "5vw" }}
                    primary="Niinaのこだわり"
                  />
                </Link>
              </label>
            </ListItem>
            <Divider />
            <ListItem
              button
              divider
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <label
                htmlFor="menu"
                style={{ width: "100%", height: "100%" }}
              >
                <Link
                  id="menu"
                  to="/menu"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemText
                    style={{ color: "white", fontSize: "5vw" }}
                    primary="メニュー"
                  />
                </Link>
              </label>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <label htmlFor="tips" style={{ width: "100%", height: "100%" }}>
                <Link id="tips" to="/tips" style={{ textDecoration: "none" }}>
                  <ListItemText
                    style={{ color: "white", fontSize: "5vw" }}
                    primary="おいしい食べ方"
                  />
                </Link>
              </label>
            </ListItem>
            <Divider light />
            {/* <ListItem button onClick={()=>{setOpenMenu(false)}}>
              <ListItemText style={{color:"white", fontSize: "5vw"}} primary="お客様の声" />
            </ListItem> */}
            <ListItem
              button
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <label htmlFor="photos" style={{ width: "100%", height: "100%" }}>
                <a
                  id="photos"
                  href="https://www.facebook.com/bakeryniina/photos"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemText
                    style={{ color: "white", fontSize: "5vw" }}
                    primary="フォトギャラリー"
                  />
                </a>
              </label>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              <label htmlFor="about" style={{ width: "100%", height: "100%" }}>
                <Link id="about" to="/about" style={{ textDecoration: "none" }}>
                  <ListItemText
                    style={{ color: "white", fontSize: "5vw" }}
                    primary="店舗情報"
                  />
                </Link>
              </label>
            </ListItem>
          </List>
        ) : null}
      </div>
      <div
        style={{
          background: "#EC6C00",
          opacity: 0.8,
          borderBottomLeftRadius: "2.5em",
        }}
      >
        <Grid
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          container
          style={{ width: "12em" }}
        >
          <img
            src=""
            style={{ padding: "0.5em", width: "2.5em" }}
            onClick={(e) => {}}
          />

          <a href="https://www.instagram.com/bakery_niina/">
            <StaticImage
              placeholder="none"
              src="../images/SVG/99.sns-insta.svg"
              style={{ margin: "0.5em", width: "2.5em", height: "2.5em" }}
              imgStyle={{ width: "100%", height: "100%" }}
              onClick={(e) => {}}
            />
          </a>

          <a href="https://www.facebook.com/bakeryniina">
            {" "}
            <StaticImage
              placeholder="none"
              src="../images/SVG/99.sns-fb.svg"
              style={{ margin: "0.5em", width: "2.5em", height: "2.5em" }}
              imgStyle={{ width: "100%", height: "100%" }}
              onClick={(e) => {}}
            />
          </a>
        </Grid>
      </div>
    </Grid>
  );
}
